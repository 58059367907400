import { Controller } from '@hotwired/stimulus'

/**
 * Controller extends native form and adds some perks:
 * - Syncs input and checkboxes state that have same name
 * - `button[data-form-target="submit"]` - disables button when form state isn't changed
 * - `form[data-form-lock-on-change-value]` - locks window if form changed but not saved
 * - `form[data-form-update-url-value]` - Adds form state to page query once submitted
 */
export default class extends Controller {
  static targets = ['submit']

  static values = {
    lockOnChange: Boolean,
    updateUrl: Boolean
  }

  initialState = ''

  isDirty = false

  get shouldLockWindow() {
    return this.lockOnChangeValue
  }

  get shouldUpdateUrl() {
    return this.updateUrlValue
  }

  connect() {
    this.initialState = this.currentState
    this.updateElements()

    this.element.addEventListener('input', this.handleFormChange)
    this.element.addEventListener('change', this.handleFormChange)
    this.element.addEventListener('submit', this.handleFormSubmit)

    if (this.shouldUpdateUrl) {
      window.addEventListener('popstate', this.onPopStateListener)
    }

    window.addEventListener('turbo:before-fetch-response', event => {
      const { response } = event.detail.fetchResponse
      if (response.redirected) {
        event.preventDefault()
        window.location.href = response.url
        // Turn on Turbo Visit once migrated from Videos
        // Turbo.visit(response.url)
      }
    })
  }

  disconnect() {
    this.element.removeEventListener('input', this.handleFormChange)
    this.element.removeEventListener('change', this.handleFormChange)
    this.element.removeEventListener('submit', this.handleFormSubmit)
    this.unlockWindow()
  }

  onPopStateListener = event => {
    if (event.state.turbo_frame_history) {
      window.Turbo.visit(document.location.href, { action: 'replace' })
    } else {
      window.location.replace(document.location.href)
    }
  }

  handleFormChange = () => {
    const isDirty = this.initialState !== this.currentState
    const hasChanged = isDirty !== this.isDirty

    if (!hasChanged) return

    this.isDirty = isDirty

    if (isDirty && this.shouldLockWindow) this.lockWindow()
    else this.unlockWindow()

    this.updateElements()
  }

  handleFormSubmit = () => {
    this.unlockWindow()
    this.isDirty = false
    this.initialState = this.currentState

    if (this.shouldUpdateUrl) {
      window.history.pushState(
        { turbo_frame_history: true },
        document.title,
        `${document.location.pathname}?${this.currentState}`
      )
    }
  }

  /**
   * Sync form elements with the same `name` inside the form
   */
  syncElements(originElement) {
    if (!originElement?.name) return

    const isCheckbox = originElement.type === 'checkbox' || originElement.type === 'radio'

    Array.from(
      this.element.querySelectorAll(
        `[name="${originElement.name}"]${isCheckbox ? `[value="${originElement.value}"]` : ''}`
      )
    )
      .filter(element => element !== originElement)
      .forEach(element => {
        // eslint-disable-next-line no-param-reassign
        if (isCheckbox) {
          element.checked = originElement.checked
          return
        }

        // eslint-disable-next-line no-param-reassign
        element.value = originElement.value
      })
  }

  updateElements() {
    const buttons = [...this.submitTargets]

    buttons.forEach(el => {
      if (el.classList.contains('u-button')) {
        // eslint-disable-next-line no-param-reassign
        el.dataset.pending = !this.isDirty
      } else {
        // eslint-disable-next-line no-param-reassign
        el.disabled = !this.isDirty
      }
    })
  }

  unlockWindow() {
    window.onbeforeunload = undefined
  }

  lockWindow() {
    window.onbeforeunload = () => ''
  }

  get currentState() {
    const state = new FormData(this.element)
    state.delete('utf8')
    state.delete('authenticity_token')
    state.delete('format')
    return new URLSearchParams(state).toString()
  }
}
